import audioTypeImage from 'assets/images/AUDIO.png'
import csvTypeImage from 'assets/images/CSV.png'
import docTypeImage from 'assets/images/DOC.png'
import imgTypeImage from 'assets/images/IMAGE.png'
import pdfTypeImage from 'assets/images/PDF.png'
import unKnownTypeImage from 'assets/images/UnKnown.png'
import videoTypeImage from 'assets/images/Video.png'
import xlsTypeImage from 'assets/images/XLS.png'
import { maxPrice } from 'config/constants'
import { DataTable } from 'types/materialModal'

export function getEnv(param: string, defaultValue?: string): string {
  return process.env[param] ?? defaultValue ?? ''
}

export function checkFileSize(files: FileList, max: number): boolean {
  if (!max) {
    return true
  }
  let isValid = true
  ;[...files].forEach((file) => {
    if (file.size / 1024 / 1024 > max) {
      isValid = false
    }
  })
  return isValid
}

export function formatDate(dateRaw: string): string {
  const date = new Date(dateRaw)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}/${month}/${day}`
}

export function checkFileType(files: FileList, accept: string): boolean {
  let isValid = true
  const accepts = accept.split(', ')
  for (let i = 0; i < files.length; i++) {
    const fileExtension = files[i].name.split('.').pop()?.toString().toLowerCase()

    if (!accepts?.includes(`.${fileExtension}`)) {
      isValid = false
      break
    }
  }
  return isValid
}

function getFileGroup(fileType: string): string {
  const imageTypes = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'svg',
    'webp',
    'heic',
    'raw',
    'ico'
  ]
  const pdfType = ['pdf']
  const csvType = ['csv']
  const xlsxType = ['xlsx']
  const docxType = ['doc', 'docx']
  const videoTypes = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'm4v', '3gp', 'webm']
  const audioTypes = [
    'mp3',
    'wav',
    'ogg',
    'flac',
    'aac',
    'wma',
    'm4a',
    'alac',
    'pcm',
    'amr',
    'aiff'
  ]

  if (imageTypes.includes(fileType)) {
    return imgTypeImage
  } else if (pdfType.includes(fileType)) {
    return pdfTypeImage
  } else if (csvType.includes(fileType)) {
    return csvTypeImage
  } else if (xlsxType.includes(fileType)) {
    return xlsTypeImage
  } else if (docxType.includes(fileType)) {
    return docTypeImage
  } else if (videoTypes.includes(fileType)) {
    return videoTypeImage
  } else if (audioTypes.includes(fileType)) {
    return audioTypeImage
  } else {
    return unKnownTypeImage
  }
}

export function getFileType(fileName: string): string | null {
  const match = fileName.match(/\.([0-9a-z]+)(?:[?#]|$)/i)
  return match ? getFileGroup(match[1]) : null
}

export function getFileFormat(fileName: string): string | null {
  const match = fileName.match(/\.([0-9a-z]+)(?:[?#]|$)/i)
  return match ? match[1] : null
}

export function convertBytesToMB(bytes: number): number {
  return parseFloat((bytes / 1024 ** 2).toFixed(4))
}

export function getMimeTypeFromFileName(fileName) {
  const extensionToMIME = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    svg: 'image/svg+xml',
    txt: 'text/plain',
    html: 'text/html',
    js: 'text/javascript',
    json: 'application/json',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    mp4: 'video/mp4',
    avi: 'video/x-msvideo'
  }
  const extension = fileName.split('.').pop().toLowerCase()

  return extensionToMIME[extension] || 'application/octet-stream'
}

export function downloadFile(fileUrl: string, fileName: string) {
  fetch(fileUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const contentType = getMimeTypeFromFileName(fileName)
      return response.blob().then((blob) => ({ blob: blob, contentType: contentType }))
    })
    .then(({ blob, contentType }) => {
      const url = window.URL.createObjectURL(new Blob([blob], { type: contentType || '' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName || 'downloaded-file')
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      console.error('Error fetching and downloading the file:', error)
    })
}

export function getFileTypeByFormat(extension: string | null | undefined): string | null {
  if (!extension) return null

  const formats = {
    audio: ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a', 'alac', 'pcm', 'amr', 'aiff'],
    pdf: ['pdf'],
    pp: ['pptx', 'pptm', 'potx'],
    video: ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'm4v', '3gp', 'webm'],
    image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp', 'heic', 'raw', 'ico'],
    excel: ['xls', 'xlsx'],
    docx: ['doc', 'docx'],
    csv: ['csv'],
    // threeD: ['fbx', 'obj', 'dae', 'pcd', 'lwo', '3mf', '3ds', 'ply', 'stl', 'gltf', 'glb']
    threeD: ['fbx', 'obj', 'dae', 'pcd', 'lwo', '3mf', '3ds', 'ply', 'gltf', 'glb']
  }

  return Object.keys(formats).find((key) => formats[key].includes(extension.toLowerCase())) || null
}

export function previewFile(record, setFileUrl, setTypeFile, setOpenModal) {
  const fileType = getFileFormat(record?.name)

  const fileTypePreview = getFileTypeByFormat(fileType)
  if (fileTypePreview && ['pdf', 'image']?.includes(fileTypePreview)) {
    window.open(record?.file, '_blank', 'noopener,noreferrer')
  } else if (fileTypePreview && ['docx', 'csv', 'pp']?.includes(fileTypePreview)) {
    setTimeout(() => {
      window.open(
        `https://docs.google.com/gview?url=${record?.file}&embedded=true`,
        '_blank',
        'noopener,noreferrer'
      )
    }, 500)
  } else if (fileTypePreview && ['excel']?.includes(fileTypePreview)) {
    setTimeout(() => {
      window.open(
        `https://view.officeapps.live.com/op/embed.aspx?src=${record?.file}`,
        '_blank',
        'noopener,noreferrer'
      )
    }, 500)
  } else {
    setFileUrl(record?.file)
    setTypeFile(fileType)
    setOpenModal('preview')
  }
}

export function mergeArraysById(array1, array2) {
  const mergedMap = new Map()

  array1.forEach((item) => {
    mergedMap.set(item.id, item)
  })

  array2.forEach((item) => {
    if (!mergedMap.has(item.id)) {
      mergedMap.set(item.id, item)
    }
  })

  return Array.from(mergedMap.values())
}

export function isAdmin() {
  const userRole = localStorage.getItem('role')

  return userRole === 'admin' ? true : false
}

export const getUnitPrice = (listProduct: any, listLaborCosts?: any) => {
  let totalPrice = 0
  if (listProduct?.length > 0) {
    totalPrice = listProduct.reduce(
      (total, item) =>
        total +
        item?.amount *
          Number(
            item.unit_price?.toString()?.replace(/,/g, '') ||
              item.sale_price?.toString()?.replace(/,/g, '')
          ),
      0
    )
    if (listLaborCosts?.length > 0) {
      const costPrice = listLaborCosts.reduce(
        (total, item) =>
          total +
          item?.amount *
            Number(
              item.unit_price?.toString()?.replace(/,/g, '') ||
                item.sale_price?.toString()?.replace(/,/g, '')
            ),
        0
      )

      totalPrice = totalPrice + costPrice
    }
    return totalPrice
  } else if (listLaborCosts?.length > 0) {
    const costPrice = listLaborCosts.reduce(
      (total, item) =>
        total +
        item?.amount *
          Number(
            item.unit_price?.toString()?.replace(/,/g, '') ||
              item.sale_price?.toString()?.replace(/,/g, '')
          ),
      0
    )

    return costPrice
  } else return 0
}

export const getUnitEditOrderPrice = (
  listProduct: any,
  listLaborCosts?: any,
  contractor_id?: number
) => {
  let totalPrice = 0
  if (listProduct?.length > 0) {
    totalPrice = listProduct
      .filter((item) => Number(item.contractor_id) === Number(contractor_id))
      .reduce(
        (sum, item) =>
          sum +
          item?.amount *
            Number(
              item.unit_price?.toString()?.replace(/,/g, '') ||
                item.sale_price?.toString()?.replace(/,/g, '')
            ),
        0
      )
    if (listLaborCosts?.length > 0) {
      const costPrice = listLaborCosts
        .filter((item) => Number(item.contractor_id) === Number(contractor_id))
        .reduce(
          (sum, item) =>
            sum +
            item?.amount *
              Number(
                item.unit_price?.toString()?.replace(/,/g, '') ||
                  item.sale_price?.toString()?.replace(/,/g, '')
              ),
          0
        )
      totalPrice = totalPrice + costPrice
    }
    return totalPrice
  } else if (listLaborCosts?.length > 0) {
    const costPrice = listLaborCosts
      .filter((item) => Number(item.contractor_id) === Number(contractor_id))
      .reduce(
        (sum, item) =>
          sum +
          item?.amount *
            Number(
              item.unit_price?.toString()?.replace(/,/g, '') ||
                item.sale_price?.toString()?.replace(/,/g, '')
            ),
        0
      )
    totalPrice = totalPrice + costPrice
    return totalPrice
  } else return 0
}

export const modifyObject = (originalObject: any) => {
  const modifiedObject = {
    ...originalObject,
    estimate_items: originalObject.estimate_items.map((item: any) => ({
      ...item,
      unit_price: formatNumber(item.sale_price),
      amount: Number(item?.amount),
      estimate_materials: item?.estimate_materials?.map((material: any) => ({
        // product_id: material.product_id,
        product_item_id: material.product_item_id,
        material_id: material.material_id,
        color: material.color,
        product_number: material.product_number,
        // created_at: material.created_at,
        unit_price: formatNumber(material.sale_price),
        unit: material.unit,
        name: material.material_name,
        amount: Number(material?.amount)
      })),
      labor_costs: item?.labor_costs?.map((labor_cost: any) => ({
        labor_name: labor_cost?.labor_name,
        unit_price: formatNumber(labor_cost?.sale_price),
        total_price: formatNumber(labor_cost?.total_price),
        labor_cost_id: labor_cost?.labor_cost_id,
        amount: Number(labor_cost?.amount)
      }))
    }))
  }
  modifiedObject.estimate_items = modifiedObject.estimate_items.map((item) => {
    delete item?.purchase_price
    delete item?.sale_price
    delete item?.created_at
    return item
  })

  modifiedObject.estimate_items = modifiedObject.estimate_items.map((item) => ({
    ...item,
    estimate_materials: item.estimate_materials.map((material) => {
      delete material?.id
      delete material?.purchase_price
      return material
    })
  }))
  return modifiedObject
}

export const modifyOrderObject = (originalObject: any) => {
  const modifiedObject = {
    ...originalObject,
    order_items: originalObject.order_items.map((item: any) => ({
      ...item,
      unit_price: item?.isEdit
        ? formatNumber(item.unit_final_price)
        : formatNumber(item.unit_price),
      note: item?.note,
      order_materials: item?.order_materials?.map((material: any) => ({
        product_id: material.product_id,
        material_id: material.material_id,
        color: material.color,
        product_number: material.product_number,
        created_at: material.created_at,
        unit_price: formatNumber(material.unit_price),
        unit: material.unit,
        name: material.name,
        amount: material?.amount,
        contractor_id: material.contractor_id,
        product_item_id: material?.product_item_id
      })),
      order_item_labor_costs: item?.order_item_labor_costs?.map((labor_cost: any) => ({
        labor_name: labor_cost?.labor_name,
        unit_price: formatNumber(labor_cost?.unit_price),
        amount: formatNumber(labor_cost?.amount),
        labor_cost_id: labor_cost?.labor_cost_id || labor_cost?.id,
        contractor_id: labor_cost.contractor_id
      }))
    }))
  }
  modifiedObject.order_items = modifiedObject.order_items.map((item) => {
    delete item?.purchase_price
    delete item?.sale_price
    delete item?.total_price
    return item
  })

  modifiedObject.order_items = modifiedObject.order_items.map((item) => ({
    ...item,
    order_materials: item.order_materials.map((material) => {
      delete material?.purchase_price
      delete material?.product_id
      delete material?.created_at
      return material
    })
  }))
  return modifiedObject
}

export const modifyBudgetObject = (originalObject: any) => {
  const modifiedObject = {
    ...originalObject,
    budget_items: originalObject.budget_items.map((item: any) => ({
      ...item,
      unit_price: formatNumber(item.unit_price),
      note: item?.note,
      budget_materials: item?.budget_materials?.map((material: any) => ({
        product_id: material.product_id,
        material_id: material.material_id,
        color: material.color,
        product_number: material.product_number,
        created_at: material.created_at,
        rate: material.rate || 0,
        unit_price: formatNumber(material.unit_price),
        unit: material.unit,
        name: material.name,
        amount: material?.amount,
        contractor_id: material.contractor_id,
        product_item_id: material?.product_item_id
      })),
      budget_item_labor_costs: item?.budget_item_labor_costs?.map((labor_cost: any) => ({
        labor_name: labor_cost?.labor_name,
        unit_price: formatNumber(labor_cost?.unit_price),
        amount: formatNumber(labor_cost?.amount),
        labor_cost_id: labor_cost?.labor_cost_id || labor_cost?.id,
        contractor_id: labor_cost.contractor_id
      }))
    })),
    budget_incurred_costs: originalObject.budget_incurred_costs.map((item: any) => ({
      ...item,
      unit_price: formatNumber(item.unit_price)
    }))
  }
  modifiedObject.budget_items = modifiedObject.budget_items.map((item) => {
    delete item?.purchase_price
    delete item?.sale_price
    delete item?.total_price
    delete item?.product
    return item
  })

  modifiedObject.budget_items = modifiedObject.budget_items.map((item) => ({
    ...item,
    budget_materials: item.budget_materials.map((material) => {
      delete material?.purchase_price
      delete material?.product_id
      delete material?.created_at
      return material
    })
  }))
  return modifiedObject
}

export const modifyActualCostObject = (originalObject: any) => {
  delete originalObject?.actual_cost_number
  delete originalObject?.contractor_id
  delete originalObject?.building_site

  const modifiedObject = {
    ...originalObject,
    actual_cost_items: originalObject.actual_cost_items.map((item: any) => ({
      ...item,
      unit_price: formatNumber(item.unit_price),
      note: item?.note,
      actual_cost_materials: item?.actual_cost_materials?.map((material: any) => ({
        product_id: material.product_id,
        material_id: material.material_id,
        color: material.color,
        product_number: material.product_number,
        created_at: material.created_at,
        unit_price: formatNumber(material.unit_price),
        unit: material.unit,
        rate: material.rate || 0,
        name: material.name,
        amount: material?.amount,
        contractor_id: material.contractor_id,
        product_item_id: material?.product_item_id
      })),
      actual_labor_costs: item?.actual_labor_costs?.map((labor_cost: any) => ({
        labor_name: labor_cost?.labor_name,
        unit_price: formatNumber(labor_cost?.unit_price),
        amount: formatNumber(labor_cost?.amount),
        labor_cost_id: labor_cost?.labor_cost_id || labor_cost?.id,
        contractor_id: labor_cost.contractor_id
      }))
    })),
    actual_incurred_costs: originalObject.actual_incurred_costs.map((item: any) => ({
      ...item,
      unit_price: formatNumber(item.unit_price)
    }))
  }
  modifiedObject.actual_cost_items = modifiedObject.actual_cost_items.map((item) => {
    delete item?.purchase_price
    delete item?.sale_price
    delete item?.total_price
    return item
  })

  modifiedObject.actual_cost_items = modifiedObject.actual_cost_items.map((item) => ({
    ...item,
    actual_cost_materials: item.actual_cost_materials.map((material) => {
      delete material?.purchase_price
      delete material?.product_id
      delete material?.created_at
      return material
    })
  }))
  return modifiedObject
}

export const modifyFinalCostObject = (originalObject: any) => {
  const modifiedObject = {
    ...originalObject,
    building_completion_items: originalObject.building_completion_items.map((item: any) => ({
      ...item,
      unit_price: formatNumber(item.unit_price),
      note: item?.note,
      building_completion_materials: item?.building_completion_materials?.map((material: any) => ({
        product_id: material.product_id,
        material_id: material.material_id,
        color: material.color,
        product_number: material.product_number,
        created_at: material.created_at,
        unit_price: formatNumber(material.unit_price),
        unit: material.unit,
        rate: material.rate || 0,
        name: material.name,
        amount: material?.amount,
        contractor_id: material.contractor_id,
        product_item_id: material?.product_item_id
      })),
      building_completion_labor_costs: item?.building_completion_labor_costs?.map(
        (labor_cost: any) => ({
          labor_name: labor_cost?.labor_name,
          unit_price: formatNumber(labor_cost?.unit_price),
          amount: formatNumber(labor_cost?.amount),
          labor_cost_id: labor_cost?.labor_cost_id || labor_cost?.id,
          contractor_id: labor_cost.contractor_id
        })
      )
    })),
    building_completion_incurred_costs: originalObject.building_completion_incurred_costs.map(
      (item: any) => ({
        ...item,
        unit_price: formatNumber(item.unit_price)
      })
    )
  }
  modifiedObject.building_completion_items = modifiedObject.building_completion_items.map(
    (item) => {
      delete item?.purchase_price
      delete item?.sale_price
      delete item?.total_price
      return item
    }
  )

  modifiedObject.building_completion_items = modifiedObject.building_completion_items.map(
    (item) => ({
      ...item,
      building_completion_materials: item.building_completion_materials.map((material) => {
        delete material?.purchase_price
        delete material?.product_id
        delete material?.created_at
        return material
      })
    })
  )
  return modifiedObject
}

export const modifyBillObject = (originalObject: any) => {
  const modifiedObject = {
    ...originalObject,
    bill_items: originalObject.bill_items.map((item: any) => ({
      ...item,
      unit_price: formatNumber(item.unit_price)
    })),
    name: originalObject?.bill_summary[0]?.name
  }

  delete modifiedObject?.bill_summary

  modifiedObject.bill_items = modifiedObject.bill_items.map((item) => {
    delete item?.estimate_materials
    delete item?.estimate_id
    delete item?.total_price
    delete item?.labor_costs
    delete item?.product
    return item
  })

  return modifiedObject
}

export const findLabelByValue = (options: any[], value: number): string | undefined => {
  const foundOption = options.find((option) => option.value === value)
  return foundOption?.title
}

export const findLabelBillStatusValue = (options: any[], value: number): string | undefined => {
  const foundOption = options.find((option) => option.value === value)
  return foundOption?.label
}

export function formatCurrency(value: string | number, isJP = false): string {
  let num = typeof value === 'string' ? parseFloat(value.replace(/[^\d.-]/g, '')) : value
  num = Math.round(num)
  if (isNaN(num)) return '0'
  return isJP ? `￥` + num.toLocaleString('en-US') : num.toLocaleString('en-US')
}

export function formatCurrencyUnrounded(value: string | number, isJP = false): string {
  const num = typeof value === 'string' ? parseFloat(value.replace(/[^\d.-]/g, '')) : value
  if (isNaN(num)) return '0'
  return isJP
    ? `￥` + num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    : num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
}

export const formatNumber = (value) => {
  return Number(value?.toString()?.replace(/￥/g, '')?.replace(/,/g, ''))
}

export const fullWidthToHalfWidth = (str) => {
  return str.replace(/[\uFF10-\uFF19]/g, function (m) {
    return String.fromCharCode(m.charCodeAt(0) - 0xfee0)
  })
}

export const validateOrderItems = (listOrderItems) => {
  return listOrderItems?.some(
    (obj) =>
      obj?.order_materials?.some(
        (mat) => !mat?.contractor_id || Number(mat?.amount) <= 0 || !mat?.amount
      ) ||
      obj?.order_item_labor_costs?.some(
        (lab) => !lab?.contractor_id || Number(lab?.amount) <= 0 || !lab?.amount
      )
  )
}

export const validateBudgetItems = (listBudgetItems) => {
  return listBudgetItems?.some(
    (obj) =>
      obj?.budget_materials?.some(
        (mat) => !mat?.contractor_id || Number(mat?.amount) <= 0 || !mat?.amount
      ) ||
      obj?.budget_item_labor_costs?.some(
        (lab) => !lab?.contractor_id || Number(lab?.amount) <= 0 || !lab?.amount
      )
  )
}

export const validateEstimateItems = (listOrderItems) => {
  return listOrderItems?.some(
    (obj) =>
      obj?.estimate_materials?.some((mat) => Number(mat?.amount) <= 0 || !mat?.amount) ||
      obj?.labor_costs?.some((lab) => Number(lab?.amount) <= 0 || !lab?.amount)
  )
}

export const search = (key, inputArray, isMaterial = true) => {
  const arr: Array<DataTable> = []
  if (key !== '') {
    if (isMaterial) {
      for (let i = 0; i < inputArray.length; i++) {
        if (inputArray[i]?.name?.toLowerCase().includes(key.toLowerCase())) {
          arr.push(inputArray[i])
        }
      }
    } else {
      for (let i = 0; i < inputArray.length; i++) {
        if (inputArray[i]?.labor_name?.toLowerCase().includes(key.toLowerCase())) {
          arr.push(inputArray[i])
        }
      }
    }

    return arr
  } else return inputArray
}

export function hasLongSalePrice(array) {
  return array?.some(
    (obj) =>
      Number(formatNumber(obj.unit_price)) >= maxPrice ||
      obj.unit_price.toString().length === 0 ||
      !obj.unit_price ||
      obj.unit_price.toString() === '' ||
      Number(obj.unit_price) === 0 ||
      !obj.contractor_id ||
      !obj.amount ||
      Number(obj.amount) <= 0 ||
      Number(formatNumber(obj.amount)) >= maxPrice
  )
}

export function fullWidthNumConvert(fullWidthNum) {
  return fullWidthNum.replace(/[\uFF10-\uFF19]/g, function (m) {
    return String.fromCharCode(m.charCodeAt(0) - 0xfee0)
  })
}

export function downloadFilePdf(response, fileName) {
  const pdfUrl = URL.createObjectURL(new Blob([response], { type: 'application/pdf' }))
  const link = document.createElement('a')
  link.href = pdfUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(pdfUrl)
}

export function downloadFileExcel(response, fileName) {
  const downloadUrl = window.URL.createObjectURL(new Blob([response]))
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.parentNode!.removeChild(link)
}

export const getParamUrl = (searchParams) => {
  const paramsObject = {}

  searchParams.forEach((value, key) => {
    if (paramsObject[key]) {
      if (Array.isArray(paramsObject[key])) {
        paramsObject[key].push(value)
      } else {
        paramsObject[key] = [paramsObject[key], value]
      }
    } else {
      paramsObject[key] = value
    }
  })
  return paramsObject
}

export const roundNumber = (num: number, decimalPlaces: number = 2): number => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.round(num * factor) / factor
}
